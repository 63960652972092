var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import classNames from 'classnames';
import RcCascader from 'rc-cascader';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import DefaultRenderEmpty from '../config-provider/defaultRenderEmpty';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import { useCompactItemContext } from '../space/Compact';
import { FormItemInputContext } from '../form/context';
import getIcons from '../select/utils/iconUtil';
import { getTransitionDirection, getTransitionName } from '../_util/motion';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import warning from '../_util/warning';
import useSelectStyle from '../select/style';
import useStyle from './style';
import genPurePanel from '../_util/PurePanel';
const { SHOW_CHILD, SHOW_PARENT } = RcCascader;
function highlightKeyword(str, lowerKeyword, prefixCls) {
    const cells = str
        .toLowerCase()
        .split(lowerKeyword)
        .reduce((list, cur, index) => (index === 0 ? [cur] : [...list, lowerKeyword, cur]), []);
    const fillCells = [];
    let start = 0;
    cells.forEach((cell, index) => {
        const end = start + cell.length;
        let originWorld = str.slice(start, end);
        start = end;
        if (index % 2 === 1) {
            originWorld = (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement("span", { className: `${prefixCls}-menu-item-keyword`, key: `seperator-${index}` }, originWorld));
        }
        fillCells.push(originWorld);
    });
    return fillCells;
}
const defaultSearchRender = (inputValue, path, prefixCls, fieldNames) => {
    const optionList = [];
    // We do lower here to save perf
    const lower = inputValue.toLowerCase();
    path.forEach((node, index) => {
        if (index !== 0) {
            optionList.push(' / ');
        }
        let label = node[fieldNames.label];
        const type = typeof label;
        if (type === 'string' || type === 'number') {
            label = highlightKeyword(String(label), lower, prefixCls);
        }
        optionList.push(label);
    });
    return optionList;
};
const Cascader = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, size: customizeSize, disabled: customDisabled, className, rootClassName, multiple, bordered = true, transitionName, choiceTransitionName = '', popupClassName, dropdownClassName, expandIcon, placement, showSearch, allowClear = true, notFoundContent, direction, getPopupContainer, status: customStatus, showArrow } = props, rest = __rest(props, ["prefixCls", "size", "disabled", "className", "rootClassName", "multiple", "bordered", "transitionName", "choiceTransitionName", "popupClassName", "dropdownClassName", "expandIcon", "placement", "showSearch", "allowClear", "notFoundContent", "direction", "getPopupContainer", "status", "showArrow"]);
    const restProps = omit(rest, ['suffixIcon']);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction: rootDirection,
    // virtual,
    // dropdownMatchSelectWidth,
     } = React.useContext(ConfigContext);
    const mergedDirection = direction || rootDirection;
    const isRtl = mergedDirection === 'rtl';
    // =================== Form =====================
    const { status: contextStatus, hasFeedback, isFormItemInput, feedbackIcon, } = React.useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // =================== Warning =====================
    if (process.env.NODE_ENV !== 'production') {
        warning(!multiple || !props.displayRender, 'Cascader', '`displayRender` not work on `multiple`. Please use `tagRender` instead.');
        warning(!dropdownClassName, 'Cascader', '`dropdownClassName` is deprecated. Please use `popupClassName` instead.');
    }
    // =================== No Found ====================
    const mergedNotFoundContent = notFoundContent || (renderEmpty === null || renderEmpty === void 0 ? void 0 : renderEmpty('Cascader')) || (React.createElement(DefaultRenderEmpty, { componentName: "Cascader" }));
    // ==================== Prefix =====================
    const rootPrefixCls = getPrefixCls();
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const cascaderPrefixCls = getPrefixCls('cascader', customizePrefixCls);
    const [wrapSelectSSR, hashId] = useSelectStyle(prefixCls);
    const [wrapCascaderSSR] = useStyle(cascaderPrefixCls);
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    // =================== Dropdown ====================
    const mergedDropdownClassName = classNames(popupClassName || dropdownClassName, `${cascaderPrefixCls}-dropdown`, {
        [`${cascaderPrefixCls}-dropdown-rtl`]: mergedDirection === 'rtl',
    }, rootClassName, hashId);
    // ==================== Search =====================
    const mergedShowSearch = React.useMemo(() => {
        if (!showSearch) {
            return showSearch;
        }
        let searchConfig = {
            render: defaultSearchRender,
        };
        if (typeof showSearch === 'object') {
            searchConfig = Object.assign(Object.assign({}, searchConfig), showSearch);
        }
        return searchConfig;
    }, [showSearch]);
    // ===================== Size ======================
    const size = React.useContext(SizeContext);
    const mergedSize = compactSize || customizeSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    // ===================== Icon ======================
    let mergedExpandIcon = expandIcon;
    if (!expandIcon) {
        mergedExpandIcon = isRtl ? React.createElement(LeftOutlined, null) : React.createElement(RightOutlined, null);
    }
    const loadingIcon = (React.createElement("span", { className: `${prefixCls}-menu-item-loading-icon` },
        React.createElement(LoadingOutlined, { spin: true })));
    // =================== Multiple ====================
    const checkable = React.useMemo(() => (multiple ? React.createElement("span", { className: `${cascaderPrefixCls}-checkbox-inner` }) : false), [multiple]);
    // ===================== Icons =====================
    const mergedShowArrow = showArrow !== undefined ? showArrow : props.loading || !multiple;
    const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { hasFeedback,
        feedbackIcon, showArrow: mergedShowArrow, multiple,
        prefixCls }));
    // ===================== Placement =====================
    const getPlacement = () => {
        if (placement !== undefined) {
            return placement;
        }
        return isRtl ? 'bottomRight' : 'bottomLeft';
    };
    // ==================== Render =====================
    const renderNode = (React.createElement(RcCascader, Object.assign({ prefixCls: prefixCls, className: classNames(!customizePrefixCls && cascaderPrefixCls, {
            [`${prefixCls}-lg`]: mergedSize === 'large',
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-rtl`]: isRtl,
            [`${prefixCls}-borderless`]: !bordered,
            [`${prefixCls}-in-form-item`]: isFormItemInput,
        }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), compactItemClassnames, className, rootClassName, hashId), disabled: mergedDisabled }, restProps, { direction: mergedDirection, placement: getPlacement(), notFoundContent: mergedNotFoundContent, allowClear: allowClear, showSearch: mergedShowSearch, expandIcon: mergedExpandIcon, inputIcon: suffixIcon, removeIcon: removeIcon, clearIcon: clearIcon, loadingIcon: loadingIcon, checkable: checkable, dropdownClassName: mergedDropdownClassName, dropdownPrefixCls: customizePrefixCls || cascaderPrefixCls, choiceTransitionName: getTransitionName(rootPrefixCls, '', choiceTransitionName), transitionName: getTransitionName(rootPrefixCls, getTransitionDirection(placement), transitionName), getPopupContainer: getPopupContainer || getContextPopupContainer, ref: ref, showArrow: hasFeedback || showArrow })));
    return wrapCascaderSSR(wrapSelectSSR(renderNode));
});
if (process.env.NODE_ENV !== 'production') {
    Cascader.displayName = 'Cascader';
}
// We don't care debug panel
/* istanbul ignore next */
const PurePanel = genPurePanel(Cascader);
Cascader.SHOW_PARENT = SHOW_PARENT;
Cascader.SHOW_CHILD = SHOW_CHILD;
Cascader._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default Cascader;
